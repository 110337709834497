<template>
    <b-card
            class="feed-view-wrapper"
    >
        <b-card>
            <app-timeline>

                <app-timeline-item
                        v-for="(feed, i) in feedList" :key="feed.feed_id"
                        :title="'#' + getCodeLabel('user', feed.user_id)"
                        :subtitle="'内容:' + feed.content +' 数据：' + feed.data"
                        :time="toTime(feed.create_time)"
                        variant="primary"
                >
                </app-timeline-item>
            </app-timeline>
        </b-card>
    </b-card>
</template>

<script>
    import {
        BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import {onUnmounted, ref} from '@vue/composition-api'
    import router from "@/router";
    import store from "@/store";
    import { useToast } from 'vue-toastification/composition'
    import { toTime, getCodeLabel} from '@core/utils/filter'
    import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
    import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
    import Timeline from "../../components/timeline/Timeline";
    import feedStore from "./feedStore";

    export default {
        components: {
            Timeline,
            BCard,
            BMedia,
            BAvatar,
            BCardText,
            BForm,
            BLink,
            BImg,
            BRow,
            BCol,
            BButton,
            BFormGroup,
            BFormInput,
            BFormFile,
            vSelect,
            useToast,

            AppTimeline,
            AppTimelineItem,
        },
        data() {
            return {
                feedList:{},
            }
        },
        props: ["object_type","object_id"],
    setup() {
        // Register module
        if (!store.hasModule('feed')) store.registerModule('feed', feedStore)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule('feed')) store.unregisterModule('feed')
        })

        const edit = function() {
            store.dispatch('feed/searchFlow',{
                object_type:'bank_account',
                object_id: 1227,
            }).then(res => {
                this.feedList = res.data.data;
            })
        }

        const cancel = function() {
            this.$router.go(-1)
        }

        return {
            edit,
            cancel,
            toTime,
            getCodeLabel,
        }
    },
        created() {
            this.id = this.$route.query.id || 0;
            this.edit()
        },
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>